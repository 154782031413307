import { Story } from '@shared/api/types/story';

export const zealyLeaderboard: Story = {
  id: 'coinlist-magicsquare-road-to-tge-leaderboard',
  type: 'ZEALY_LEADERBOARD',
  status: 'ACTIVE',
  metadata: {
    title: 'Magic Square $SQR Road to TGE with CoinList Leaderboard',
    description:
      "Track your rank in Magic Square's Road to TGE with CoinList campaign! Stay on top of the leaderboard for a chance at the 2,500,000 $SQR prize pool",
  },
};
