import { Story } from '@shared/api/types/story';
import generateUrl from '@shared/helpers/imageProxy';

export const stormTrade: Story = {
  id: 'storm-trade-road-to-ido-5m-prize-pool-magic-launchpad',
  type: 'STORM_TRADE',
  status: 'ACTIVE',
  metadata: {
    title: 'Storm Trade 5M $STORM Road-to-IDO Campaign | Powered by Magic Square',
    description:
      "Join the Storm Trade Road-to-IDO Campaign on Magic Square's Magic Launchpad! Compete for $5M in $STORM prizes. Join now and win big!",
    preview: {
      url: generateUrl({
        url: '/launchpad/stories/st/storm-trade-banner-tablet.png',
        width: 1200,
        height: 623,
        resizeType: 'fill',
        quality: 100,
        format: 'png',
      }),
      width: '1200',
      height: '623',
    },
  },
};
